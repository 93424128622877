<template>
    <AModal
        :visible="visible"
        title="Detail Appreciation Tiers"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        :footer="null">
        <ARow>
            <ACol :span="8">Nama</ACol>
            <ACol>: {{ detail.name }}</ACol>
        </ARow>
        <ARow>
            <ACol :span="8">Deskripsi</ACol>
            <ACol>: {{ detail.description }}</ACol>
        </ARow>
    </AModal>
</template>
<script>
import {
    defineComponent,
    ref,
} from 'vue'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const detail = ref(props.item)

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        return {
            handleModalCancel,
            detail,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
