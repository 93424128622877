<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    allow-clear
                    placeholder="Cari..."
                    style="width:300px"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="btnForm">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
                <a-button
                    title="download excel"
                    type="primary"
                    @click="btnDownloadExcel"
                    :loading="state.isDownloading">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
                    <span v-else>Downloading ...</span>
                </a-button>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip title="Detail">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDetail(record)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                        <ATooltip title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <!-- <ATooltip title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDelete(record.id)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip> -->
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- detail -->
        <Detail
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:item="visibleDetailItemModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import Detail from './Detail'
import { pickBy } from 'lodash'
import moment from 'moment'

export default defineComponent({
    components: {
        FormCreateOrUpdate,
        Detail,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'No',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Nama',
                    dataIndex: 'name',
                },
                {
                    title: 'Deskripsi',
                    dataIndex: 'description',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                page: 1,
                "per-page": 10,
            },
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get('/api/appreciation-tiers', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // download export excel
        const btnDownloadExcel = () => {
            state.isDownloading = true
            apiClient
                .get('/api/appreciation-tiers', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Appreciation_Tiers_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownloading = false
                })
        }

        const btnDelete = id => {
            Modal.confirm({
                title: 'Konfirmasi hapus jadwal kunjungan salesman',
                content: 'Apakah anda ingin hapus jadwal kunjungan salesman?',
                onOk() {
                    apiClient
                        .delete(`/api/appreciation-tiers/${id}`)
                        .then((response) => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = item
        }

        // handle detail
        const visibleDetailModal = ref(false);
        const visibleDetailItemModal = ref(null);

        const btnDetail = (item = null) => {
            visibleDetailModal.value = true
            visibleDetailItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            visibleFormModal,
            visibleFormItemModal,
            visibleDetailModal,
            visibleDetailItemModal,
            btnForm, // create or update
            btnDelete,
            btnDetail,
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            btnDownloadExcel,
            errorMessage,
        }
    },
})
</script>
